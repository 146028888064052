import React, { useState, useContext } from 'react';
import { useRouter } from 'next/router';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { connect } from 'react-redux';
import Axios from 'axios';
import Swal from 'sweetalert2';

import { actions as cartAction } from '~/store/cart';
import { actions as wishlistAction } from '~/store/wishlist';
import { actions as otherAction } from '~/store/other';
import { actions as compareAction } from '~/store/compare';

import { getUserData, isInCompare, isInWishlist } from '~/utils';

import ALink from '~/components/features/alink';
import { LangContext } from '~/context/lang';
import { AuthContext } from '~/context/auth';


function Product(props) {
    const { product, wishlist, lang } = props;
    const router = useRouter();
    let { getLang } = useContext(LangContext);
    let { directLogout } = useContext(AuthContext);
    let language = getLang("product");
    const [qty, setQty] = useState(1);
    const user = getUserData();

    let onCartClick = async (qty) => {
        if (qty > product.stock) {
            Swal.fire(language.warning, `${language.qtyError} ${product.stock}`, 'warning');
        } else {
            if (0 !== product.stock) {
                await Axios({
                    method: 'post',
                    responseType: 'json',
                    url: '/api/basket/',
                    headers: {
                        "Authorization": user ? user.token : null,
                    },
                    data: {
                        action: 'basketAdd',
                        productID: product.id,
                        qty: qty,
                        unit: parseInt(product.prices[0].unit_id),
                        branchId: product.prices[0].branch_id,
                        userToken: window.localStorage.getItem('userToken')
                    }
                }).then((addBasket) => {
                    if (addBasket.data.status === 1) {
                        props.addToCart(product, qty, lang);
                    } else if (addBasket.data.status === 7) {
                        Swal.fire(language.warning, language.maximumQty, 'error');
                    } else if (addBasket.data.status === 8) {
                        directLogout();
                    }
                })
            }
        }
    }

    async function onWishlistClick(e) {
        e.preventDefault();
        if (user.token) {
            if (!isInWishlist(props.wishlist, product)) {
                await Axios({
                    method: 'post',
                    responseType: 'json',
                    url: '/api/basket/',
                    headers: {
                        "Authorization": user ? user.token : null,
                    },
                    data: {
                        action: 'addWishList',
                        productID: product.id,
                    }
                }).then(({ data }) => {
                    if (data.status === 1) {
                        props.addToWishlist(product, true, lang);
                    }
                })
            } else {
                router.push('/wishlist');
            }
        } else {
            props.addToWishlist(product, true, lang);
        }
    }

    let removeFromWishlistHandler = async (e) => {
        e.preventDefault();
        await Axios({
            method: 'post',
            responseType: 'json',
            url: '/api/basket/',
            headers: {
                "Authorization": user ? user.token : null,
            },
            data: {
                action: 'deleteWishList',
                productID: product.id,
            }
        }).then(({ data }) => {
            if (data.status === 1) {
                props.removeFromWishlist(product, lang)
            }
        })
    }

    function onQuickView(e) {
        e.preventDefault();
        props.showQuickView(product);
    }

    function onCompareClick(e) {
        e.preventDefault();
        if (!isInCompare(props.comparelist, product)) {
            props.addToCompare(product, lang);
        } else {
            props.removeFromCompare(product, lang);
        }
    }

    // useEffect(() => {
    //     product?.attributes?.map((x, y) => {
    //         x.attr_group_name === 'Color' || x.attr_group_name === 'اللون' ?
    //             y == 1 &&
    //             setSelectedColor(x.attr_val_id)
    //             : ''
    //     }
    //     )
    // }, [lang])
    return (
        <div className="product product-4 rounded-0 w-100">
            <ALink href={`/product/${product.url}`}>

                <figure className="product-media bg-white">
                    {
                        product.new &&
                        <span className="product-label label-new">{language.new}</span>
                    }
                    {
                        product.top &&
                        <span className="product-label label-new">{language.top}</span>
                    }
                    {
                        product.discount_price > 0 &&
                        <span className="product-label label-sale">{language.sale}</span>
                    }
                    {
                        !product.stock || product.stock == 0 &&
                        <span className="product-label label-out">{language.outOfStock}</span>
                    }
                    {

                        product.productImgs.length > 0 ?
                            <>
                                <LazyLoadImage
                                    id={`productImage`}
                                    className={`product-images`}
                                    width={250}
                                    alt="product"
                                    src={`${process.env.IMG_URL}products/${product.productImgs[0].imgFile}`}
                                    threshold={500}
                                    effect="black and white"
                                    wrapperClassName="product-image"
                                />
                            </>
                            :
                            <LazyLoadImage
                                alt="product"
                                src={process.env.IMG_URL + 'products/noImage.webp'}
                                threshold={500}
                                effect="black and white"
                                wrapperClassName="product-image"
                            />
                    }
                    {
                        product.productImgs.length >= 2 ?
                            <LazyLoadImage
                                alt="product"
                                src={process.env.IMG_URL + 'products/' + product.productImgs[1].imgFile}
                                threshold={500}
                                effect="black and white"
                                wrapperClassName="product-image-hover"
                            />
                            : ""
                    }

                </figure>
                <div className="product-actions">
                    {
                        isInWishlist(wishlist, product) ?
                            <a href="#" className="btn-product-icon btn-wishlist added-to-wishlist" onClick={removeFromWishlistHandler}></a>
                            :
                            <a href="#" className="btn-product-icon btn-wishlist " onClick={onWishlistClick}></a>
                    }
                    <button type='button' className="btn-product-icon btn-quickview" title={language.quickPreview} onClick={onQuickView}><span>{language.quickPreview}</span></button>
                    <button type='button' className="btn-product-icon btn-compare" title={language.compare} onClick={onCompareClick}><span>{language.compare}</span></button>
                </div>
                <div className="product-body bg-transparent">
                    <div className="d-flex justify-content-center align-items-center">
                        <ALink href={`/product/${product.url}`}>
                            <h3 className="product-title overflow-hidden letter-spacing-normal">
                                {product.name}
                                <span className='mx-2' style={{ fontSize: '1.1rem' }}>({product?.prices[0]?.unit})</span>
                            </h3>
                        </ALink>

                    </div>
                    <div className="ratings-container">
                        <div className="ratings">
                            <div className="ratings-val" style={{ width: product.ratings * 20 + '%' }}></div>
                        </div>
                        <span className="ratings-text">({product.reviews})</span>
                    </div>
                    {
                        !product.stock || product.stock == 0 ?
                            <div className="product-price align-items-center d-flex justify-content-center">
                                <span className="out-price">{product.price.toFixed(2)}&nbsp;<span>SAR</span></span>
                            </div>
                            :
                            product.discount_price > 0 ?
                                <div className="product-price">
                                    <span className="old-price">{product.price.toFixed(2)}<span className='price-type'>SAR</span></span>
                                    <span className="new-price">{product.discount_price.toFixed(2)}<span className='price-type'>SAR</span></span>
                                </div>
                                :
                                <div className="product-price justify-content-center align-items-center d-flex mb-1">
                                    <div className="product-price">{product.price.toFixed(2)}&nbsp;<span className='price-type'>SAR</span></div>
                                </div>
                    }
                </div>
            </ALink>
            <div className="product-basket-btn text-center">
                <button className="" title={language.addToCart} onClick={() => { onCartClick(qty) }}>
                    <img src='/images/icons/add-to-card-icon.svg' width={40} alt='add-to-cart' />
                </button>
            </div>
        </div>
    )
}
const mapStateToProps = (state) => {
    return {
        wishlist: state.wishlist.data,
        comparelist: state.comparelist.data
    }
}
export default connect(mapStateToProps, { ...wishlistAction, ...cartAction, ...otherAction, ...compareAction })(Product);