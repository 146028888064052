
import { useState, useEffect } from 'react';
import Axios from 'axios';
// import Custom Component
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { getUserData, partnerSliderSettings } from '~/utils';
import PartnerItem from '../elements/partner-item';

function OurPartners(props) {
    const { language, lang } = props;
    const [partners, setPartners] = useState([]);
    const user = getUserData();

    let getOurPartners = async () => {
        await Axios({
            method: 'post',
            responseType: 'json',
            url: '/api/home/',
            headers: {
                "Authorization": user ? user.token : null,
            },
            data: {
                action: "getPartners",
                lang: lang,
            }
        }).then((x) => {
            setPartners(x.data);
        }).catch((err) => {
            console.log(err);
        });
    }

    useEffect(() => {
        getOurPartners();
    }, [lang])
    return (
        <div className="container overflow-hidden">
            {
                partners.length > 0 &&
                <section className="special-prices">
                    <div className="container">
                        <div className="section-heading">
                            <h2 className="title align-self-center letter-spacing-normal" dir='ltr' dangerouslySetInnerHTML={{ __html: language.ourPartners }}></h2>
                        </div>
                        <div className="flash-content special-prices-slider mt-2 py-2 pb-7">
                            <Slider {...partnerSliderSettings}>
                                {
                                    partners.slice(0, 12).map((item, index) =>
                                        <PartnerItem partner={item} key={"Partner:" + index} />
                                    )
                                }
                            </Slider>
                        </div>
                    </div>
                </section>
            }
        </div>
    )
}
export default OurPartners;
