import React, { useState, useEffect } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Modal from 'react-modal';
import Cookie from 'js-cookie';
import ALink from '../alink';

const customStyles = {
    overlay: {
        backgroundColor: 'rgba(51,51,51,0.6)',
        zIndex: '10001'
    }
};

Modal.setAppElement('body');

function BannerModal({ language }) {
    const [open, setOpen] = useState(false);
    const [doNotShow, setDoNotShow] = useState(false);
    const bannerSettings = JSON.parse(window.localStorage.getItem('popupBanner'));

    useEffect(() => {
        if (bannerSettings?.popup_img_file) {
            let timer;
            Cookie.get(`banner-modal`) || (timer = setTimeout(() => {
                setOpen(true);
            }, 10000));

            return () => {
                timer && clearTimeout(timer);
            };
        }
    }, [bannerSettings])

    function closeModal(e) {
        document.getElementById("banner-modal").classList.remove("ReactModal__Content--after-open");

        if (document.querySelector(".ReactModal__Overlay")) {
            document.querySelector(".ReactModal__Overlay").style.opacity = '0';
        }

        setTimeout(() => {
            setOpen(false);
            doNotShow && Cookie.set(`banner-modal`, "true", { expires: 7 });
        }, 350);
    }

    function handleChange(e) {
        setDoNotShow(e.target.checked);
    }

    return (
        <Modal
            isOpen={open}
            onRequestClose={closeModal}
            style={customStyles}
            shouldReturnFocusAfterClose={false}
            contentLabel="Newsletter Modal"
            className="container newsletter-popup-container h-auto"
            overlayClassName="d-flex align-items-center justify-content-center newsletter-modal"
            id="banner-modal"
        >
            <div className="modal-content overflow-hidden banner-modal">
                <div className="row">
                    <div className="col-12">
                        <h4 className='banner-modal-header'>{bannerSettings?.popup_header_title}</h4>
                    </div>
                    <div className="col-12">
                        <div className="row no-gutters bg-white newsletter-popup-content">
                            <ALink href={bannerSettings.popup_action_link} target={"_blank"}>
                                <div className="col-lg-12 banner-modal-image">
                                    <LazyLoadImage
                                        src={`${process.env.IMG_URL}banners/${bannerSettings?.popup_img_file}`}
                                        width={950}
                                        height={410}
                                        effect='blur'
                                    />
                                </div>
                            </ALink>
                        </div>
                    </div>
                    <div className="col-lg-12 banner-modal-check">
                        <div className="custom-control custom-checkbox">
                            <input type="checkbox" className="custom-control-input" id="dont-show-again" onChange={handleChange} />
                            <label className="custom-control-label" htmlFor="dont-show-again" style={{ marginTop: "-.2rem" }}>{language.dontShowAgain}</label>
                        </div>
                    </div>
                    <button title={language.closeEsc} type="button" className="mfp-close" onClick={closeModal}><span>×</span></button>
                </div>
            </div>
        </Modal>
    );
}

export default BannerModal;