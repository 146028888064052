import ALink from "~/components/features/alink";

let IntroductionBanner = ({ language, topBanners }) => {
    const introduction = [
        {
            title: language.plusplus,
            text: language.plusplusText,
            icon: 'plusplus.svg',
            color: '#EE1D1B',
            link: topBanners.plusplus_link
        },
        {
            title: language.thrifts,
            text: language.thriftsText,
            icon: 'thrifts.svg',
            color: '#5064AD',
            link: topBanners.thrifts_link
        },
        {
            title: language.magazine,
            text: language.magazineText,
            icon: 'magazine-offers.svg',
            color: '#F8872A',
            link: topBanners.magazine_link
        },
    ]
    return (
        <div className="introduction-banner">
            <div className="container">
                <div className="row">
                    {
                        introduction.map((x, y) =>
                            <div className="col-lg-4 col-sm-6" key={y}>
                                <ALink href={x.link ?? ''} target={"_blank"}>
                                    <div className="introduction-banner-item" style={{ backgroundColor: x.color }}>
                                        <div className="introduction-banner-item-text">
                                            <strong>{x.title}</strong>
                                            <div>{x.text}</div>
                                        </div>
                                        <div className="introduction-banner-item-icon">
                                            <img src={`/images/icons/${x.icon}`} alt={x.title} />
                                        </div>
                                    </div>
                                </ALink>
                            </div>
                        )
                    }
                </div>
            </div>
        </div>
    )
}

export default IntroductionBanner;