import { isMobile } from 'react-device-detect';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import ALink from '~/components/features/alink';

function Banner(props) {
    const { language, banner } = props;
    let banners = [];
    let bannerContentPosition = "";

    if (banner.content_position == "center") {
        bannerContentPosition = {
            left: "50%",
            transform: "translate(-50%,-50%)",
        }
    } else if (banner.content_position == "right") {
        bannerContentPosition = {
            left: "unset",
            right: "4rem"
        }
    }

    if (banner) {

        banners.push(banner);
        if (banner.groupInfo) {
            banners.push(...banner.groupInfo);
        }
        console.log(banners);
    }


    return (
        <>
            <div className='container banners'>
                <div className='row'>
                    <div className={`col-lg-${banners.length > 4 ? 7 : 12}`}>
                        <div className="banners-group">
                            <div className="row justify-content-between">
                                {
                                    banners.map((banner, index) => (
                                        index < 4 &&
                                        <div className={`col-lg-${banners.length > 4 ? 6 : "auto"} mb-5`}>
                                            <div className={`banner banner-overlay`} style={{ textAlign: banner.text_align, backgroundColor: banner.bg_color }} key={`banner_${index}`}>
                                                {
                                                    banner.actionLink != "" ?
                                                        <a href={`${banner.actionLink}`}>
                                                            <div className="lazy-overlay bg-2"></div>
                                                            <LazyLoadImage
                                                                src={`${process.env.IMG_URL}banners/${banner.imgFile}`}
                                                                alt={banner.head}
                                                                width={banner.width}
                                                                height={banner.height}
                                                                effect="blur"
                                                            />
                                                        </a>
                                                        :
                                                        <>
                                                            <div className="lazy-overlay bg-2"></div>
                                                            <LazyLoadImage
                                                                src={`${process.env.IMG_URL}banners/${banner.imgFile}`}
                                                                alt={banner.head}
                                                                width={banner.width}
                                                                height={banner.height}
                                                                effect="blur"
                                                            />
                                                        </>

                                                }

                                                <div className="banner-content" style={{ color: banner.txt_color, ...bannerContentPosition }}>
                                                    <h4 className="banner-subtitle"><a href={`${banner.actionLink}`} style={{ color: banner.txt_color }}>{banner.head}</a></h4>
                                                    <h3 className="banner-title"><a href={`${banner.actionLink}`} style={{ color: banner.txt_color }} dangerouslySetInnerHTML={{ __html: banner.text }}></a></h3>
                                                    {
                                                        !isMobile &&
                                                            banner.actionLink != "" ?
                                                            banner.button_show == 1 ?
                                                                <div style={{ textAlign: banner.button_position }}>
                                                                    <ALink href={banner.actionLink} className={"btn btn-dark"} style={{ width: banner.button_width + "px", height: banner.button_height + "px", backgroundColor: banner.button_bg_color, borderColor: banner.button_bg_color, fontSize: banner.button_font_size + 'px', color: banner.button_text_color }}>
                                                                        {banner.buttonText}
                                                                    </ALink>
                                                                </div>
                                                                :
                                                                ''
                                                            :
                                                            ''
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                    {
                        banners.length > 4 &&
                        <div className={`col-lg-5`}>
                            <div className={`banner banner-overlay`} style={{ textAlign: banners[4].text_align, backgroundColor: banners[4].bg_color }} key={`banner_4`}>
                                {
                                    banners[4].actionLink != "" ?
                                        <a href={`${banners[4].actionLink}`}>
                                            <div className="lazy-overlay bg-2"></div>
                                            <LazyLoadImage
                                                src={`${process.env.IMG_URL}banners/${banners[4].imgFile}`}
                                                alt={banners[4].head}
                                                width={banners[4].width}
                                                height={banners[4].height}
                                                effect="blur"
                                            />
                                        </a>
                                        :
                                        <>
                                            <div className="lazy-overlay bg-2"></div>
                                            <LazyLoadImage
                                                src={`${process.env.IMG_URL}banners/${banners[4].imgFile}`}
                                                alt={banners[4].head}
                                                width={banners[4].width}
                                                height={banners[4].height}
                                                effect="blur"
                                            />
                                        </>

                                }

                                <div className="banner-content" style={{ color: banners[4].txt_color, ...bannerContentPosition }}>
                                    <h4 className="banner-subtitle"><a href={`${banners[4].actionLink}`} style={{ color: banners[4].txt_color }}>{banners[4].head}</a></h4>
                                    <h3 className="banner-title"><a href={`${banners[4].actionLink}`} style={{ color: banners[4].txt_color }} dangerouslySetInnerHTML={{ __html: banners[4].text }}></a></h3>
                                    {
                                        !isMobile &&
                                            banners[4].actionLink != "" ?
                                            banners[4].button_show == 1 ?
                                                <div style={{ textAlign: banners[4].button_position }}>
                                                    <ALink href={banners[4].actionLink} className={"btn btn-dark"} style={{ width: banners[4].button_width + "px", height: banners[4].button_height + "px", backgroundColor: banners[4].button_bg_color, borderColor: banner.button_bg_color, fontSize: banner.button_font_size + 'px', color: banner.button_text_color }}>
                                                        {banners[4].buttonText}
                                                    </ALink>
                                                </div>
                                                :
                                                ''
                                            :
                                            ''
                                    }
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </>
    )
}
export default Banner;