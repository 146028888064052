import React, { useEffect, useState } from 'react';
import ALink from '~/components/features/alink';
import Axios from 'axios';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { isMobile } from 'react-device-detect';

function HomeSlider({ lang, place }) {
    const [sliders, setSliders] = useState([]);
    function SampleNextArrow(props) {
        const { className, style, onClick } = props;
        return (
            <div
                className={className}
                style={{ ...style }}
                onClick={onClick}
            >
                <img src='/images/icons/right-arrow.svg' width={56} alt='down-arrow' />
            </div>
        );
    }

    function SamplePrevArrow(props) {
        const { className, style, onClick } = props;
        return (
            <div
                className={className}
                style={{ ...style }}
                onClick={onClick}
            >
                <img src='/images/icons/left-arrow.svg' width={56} alt='down-arrow' />
            </div>
        );
    }

    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        autoplay: true,
        autoplaySpeed: 5000,
        slidesToShow: 1,
        slidesToScroll: 1,
        rtl: lang == 'ar',
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    rtl: lang == 'ar',
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false
                }
            },
            {
                breakpoint: 600,
                settings: {
                    rtl: lang == 'ar',
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false
                }
            },
            {
                breakpoint: 480,
                settings: {
                    rtl: lang == 'ar',
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false
                }
            }
        ]
    };

    let getSliders = async () => {
        await Axios({
            method: 'post',
            responseType: 'json',
            url: '/api/home/',
            data: {
                lang: lang,
                action: 'getSliders',
                place
            }
        }).then((slider) => {
            setSliders(slider.data);
        }).catch((err) => {
            console.log(err);
        })
    };

    useEffect(() => {
        getSliders();
    }, [lang])

    return (
        <>
            <div className="page-content">
                <div className="container">
                    <div className="intro-section home-slider">
                        <Slider {...settings}>
                            {
                                sliders.length > 0 &&
                                sliders.map((slider, index) =>
                                    <div className="" key={index}>
                                        <div className="home-slider-left" >
                                            <img style={{ width: "100%", objectFit: "cover" }} src={`${process.env.IMG_URL}sliders/${slider.imgFile}`} alt={"slider_" + index} />
                                            <div className={`intro-content intro-content-left`} style={
                                                {
                                                    textAlign: slider.button_position,
                                                    left: (slider.content_position === 'center' ? '50%' : slider.content_position === 'right' ? '85%' : '5%'),
                                                    transform: (slider.content_position === 'center' ? 'translate(-50%, -50%)' : slider.content_position === 'right' ? 'translate(10%, -50%)' : 'translate(5%, -50%)')
                                                }
                                            }
                                            >
                                                {
                                                    <>
                                                        {/* <Reveal keyframes={fadeInLeft} delay={400} duration={1000}> */}
                                                        <h1 className="intro-title fw-light">{slider.headText}</h1>
                                                        {/* </Reveal> */}
                                                        {/* <Reveal keyframes={fadeInLeft} delay={400} duration={1000}> */}
                                                        <>
                                                            <h3 className="intro-desc fw-light" style={{ color: slider.descColor }}>{slider.sliderText}</h3>
                                                        </>
                                                        {/* </Reveal> */}
                                                        {
                                                            !isMobile &&
                                                                slider.button_show == 1 ?
                                                                <div style={{ textAlign: slider.button_position }}>
                                                                    <ALink href={slider.actionLink} className={"btn btn-dark"} style={{ width: slider.button_width + "px", height: slider.button_height + "px", backgroundColor: slider.button_bg_color, borderColor: slider.button_bg_color, fontSize: slider.button_font_size + 'px', color: slider.button_text_color }}>
                                                                        {slider.buttonText}
                                                                    </ALink>
                                                                </div>
                                                                :
                                                                ''
                                                        }
                                                    </>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                        </Slider>
                    </div>
                </div>
            </div>
        </>
    )
}
export default React.memo(HomeSlider);
