import Head from "next/head";
import { capitalize } from "~/utils";

let MetaTitle = ({ title }) => {
    return (
        <Head>
            <title>Mkhazin | {capitalize(title.replace("-", " "))}</title>
            <meta name="keywords" content={'Mkhazin - ' + capitalize(title.replace("-", " "))} />
            <meta name="description" content={'Mkhazin - ' + capitalize(title.replace("-", " "))} />
        </Head>
    )
}
export default MetaTitle;