let TopInfoBanner = ({ language, topBanners }) => {
    return (
        <div className="container">
            <div className="col-lg-11 m-auto">
                <div className="top-info-banner">
                    <div className="top-info-banner-item active">
                        <div className="top-info-banner-title">
                            <img src="/images/icons/delivery-time.svg" alt="delivery-time" />
                            <span>{language.deliveryTime}</span>
                        </div>
                        <ul className="top-info-banner-features">
                            <li>
                                <div>{language.deliveryOn}</div>
                                <div>{topBanners.delivery_on}</div>
                            </li>
                            <li>
                                <div>{language.freeDelivery}</div>
                                <div>{topBanners.free_delivery}</div>
                            </li>
                        </ul>
                    </div>
                    <div className="top-info-banner-item">
                        <div className="top-info-banner-title">
                            <img src="/images/icons/fast-delivery.svg" alt="delivery-time" />
                            <span>{language.fastDelivery}</span>
                        </div>
                        <ul className="top-info-banner-features">
                            <li>
                                <div>{language.deliveryRange}</div>
                                <div>{topBanners.delivery_range}</div>
                            </li>
                            <li>
                                <div>{language.products}</div>
                                <div>{topBanners.products_count}</div>
                            </li>
                            <li>
                                <div>{language.minimumDelivery}</div>
                                <div>{topBanners.minimum_delivery}</div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default TopInfoBanner;