import Axios from "axios";
import { useEffect, useState } from "react";
import ALink from "~/components/features/alink";

let CategoryList = ({ lang }) => {
    const [categories, setCategories] = useState([]);
    const [allCats, setAllCats] = useState([]);

    let allCategories = [];

    let getCategories = async () => {
        await Axios({
            method: 'post',
            responseType: 'json',
            url: 'api/categories/',
            data: {
                lang
            },
        }).then(({ data }) => {
            setCategories(data.categories);
        })
    }

    useEffect(() => {
        getCategories();
    }, []);

    useEffect(() => {
        if (categories.length > 0) {
            categories.forEach((x) =>
                allCategories.length < 16 &&
                allCategories.push({ catName: x.catName, catUrl: x.catUrl, catImage: x.catImage })
            )

            if (allCategories.length < 16) {
                categories.forEach((c) =>
                    c.subCategories.forEach((s) =>
                        allCategories.length < 16 &&
                        allCategories.push({ catName: s.catName, catUrl: s.catUrl, catImage: s.catImage })
                    )
                )
            }
            setAllCats(allCategories);
        }
    }, [categories]);

    return (
        <div className="container">
            <div className="category-lists">
                {
                    allCats.map((c, y) =>
                        <div className="category-lists-item" key={y}>
                            <ALink href={`/c/${c.catUrl}`}>
                                <div className="category-lists-item-image">
                                    <img src={`${process.env.IMG_URL}category/${c.catImage}`} alt={c.catName} />
                                </div>
                                <div className="category-lists-item-text">
                                    {c.catName}
                                </div>
                            </ALink>
                        </div>
                    )
                }
            </div>
        </div>
    )
}
export default CategoryList;